import { AuthenticatedProfile } from './interfaces';
import {
  AuthUserAction,
  AUTH_USER,
  LogoutUserAction,
  LOGOUT_USER,
  RefreshTokenAndRetryAction,
  REFRESH_TOKEN_AND_RETRY,
  StoreUserAuthAction,
  STORE_USER_AUTH
} from './types';

export const doAuthUser = (
  redirectUrl?: AuthUserAction['redirectUrl'],
  ccgid?: string,
  resume?: boolean
): AuthUserAction => ({
  type: AUTH_USER,
  redirectUrl,
  ccgid,
  resume
});

export const doStoreUserAuth = (profile?: AuthenticatedProfile): StoreUserAuthAction => ({
  profile,
  type: STORE_USER_AUTH
});

export const doLogoutUser = (
  redirectUrl?: LogoutUserAction['redirectUrl'],
  skipAuth0?: LogoutUserAction['skipAuth0'],
  destinationUrl?: LogoutUserAction['destinationUrl']
): LogoutUserAction => ({
  type: LOGOUT_USER,
  redirectUrl,
  skipAuth0,
  destinationUrl
});

export const doRefreshTokenAndRetry = <T>(retryAction: T): RefreshTokenAndRetryAction<T> => ({
  type: REFRESH_TOKEN_AND_RETRY,
  retryAction
});
