/// <reference types="auth0-js" />
import Auth0Lock from 'auth0-lock';
import IntlMessageFormat from 'intl-messageformat';
import jwt from 'jsonwebtoken';
import getConfig from 'next/config';
import { availableLocales, defaultLocale, getTranslations, Locale } from '../locales';
import { AuthenticatedProfile, AuthPreflightCheck } from '../redux/modules/auth/interfaces';
import axios from 'axios';
import { LOGIN_REDIRECT_URL_KEY } from 'src/redux/modules/auth/sagas';

let hooked = false;
let onViewChange_: ((newView: viewType) => void) | null = null;
let onLoadingStateChange_: ((loadingState: loadingStateType) => void) | null = null;

export type viewType = 'unknown' | 'login' | 'join' | 'resetpassword' | 'authentication-in-progress';
export type loadingStateType = 'initial' | 'loading-content';

interface Auth0LockStaticInstance extends Auth0LockStatic {
  id?: string;
}

let lock: Auth0LockStaticInstance | undefined = undefined;
let locks: Auth0LockStaticInstance[] = [];
let lockConfig: Auth0LockConstructorOptions | undefined = undefined;

const parseProfile = (auth0User: auth0.Auth0UserProfile, authResult: AuthResult): AuthenticatedProfile => {
  let firstName = auth0User.given_name;
  let lastName = auth0User.family_name;

  if (auth0User && auth0User.user_metadata) {
    if (auth0User.user_metadata.firstName && auth0User.user_metadata.lastName) {
      firstName = auth0User.user_metadata.firstName;
      lastName = auth0User.user_metadata.lastName;
    }

    if (auth0User.user_metadata.firstname_processed && auth0User.user_metadata.lastname_processed) {
      firstName = auth0User.user_metadata.firstname_processed;
      lastName = auth0User.user_metadata.lastname_processed;
    }
  }

  return {
    ...auth0User,
    firstName: firstName ? firstName.trim() : firstName,
    lastName: lastName ? lastName.trim() : lastName,
    accessToken: authResult.accessToken,
    tokenPayload: authResult.idTokenPayload || jwt.decode(authResult.accessToken)
  };
};

const getLock = async (locale?: Locale, ccgid?: string): Promise<Auth0LockStaticInstance> => {
  const { publicRuntimeConfig } = getConfig();
  const {
    ['signup.fieldFirstNamePlaceholder']: firstNamePlaceholder = 'Placeholder',
    ['signup.fieldLastNamePlaceholder']: lastNamePlaceholder = 'Placeholder'
  } = await getTranslations(locale, ['signup.fieldFirstNamePlaceholder', 'signup.fieldLastNamePlaceholder']);
  const language = locale && availableLocales.includes(locale) ? locale : defaultLocale();

  // Don't pass on ccgid parameter to signup_via_url.
  const signup_via_url = window.location.href.replace(/(\?|&)ccgid=[^&]*(&|)/g, '$1').replace(/\?$/, '');
  let ggc_continuation_url = signup_via_url;
  // Register final target as resume url.
  if (ggc_continuation_url.search(/redirectUrl=.+/) > -1) {
    const ggc_continuation_url_object = new URL(ggc_continuation_url);
    const redirectUrl = ggc_continuation_url_object?.searchParams?.get('redirectUrl');
    if (redirectUrl) {
      try {
        const redirectUrlObject = new URL(
          decodeURI(redirectUrl),
          window.location.protocol + '//' + window.location.hostname
        );
        ggc_continuation_url = redirectUrlObject.toString();
      } catch (e) {
        // Use what we have - will usually work, just not as efficient.
      }
    }
  }
  const additionalSignUpFields = [
    {
      name: 'given_name',
      placeholder: new IntlMessageFormat(firstNamePlaceholder, locale).format(),
      storage: 'root'
    },
    {
      name: 'family_name',
      placeholder: new IntlMessageFormat(lastNamePlaceholder, locale).format(),
      storage: 'root'
    },
    {
      // Being stored in user_metadata
      type: 'hidden',
      name: 'language',
      value: language
    },
    {
      // Being stored in user_metadata
      type: 'hidden',
      name: 'signup_via_url',
      value: signup_via_url
    },
    {
      // Being stored in user_metadata
      type: 'hidden',
      name: 'ggc_continuation_url',
      value: ggc_continuation_url
    }
  ];
  let allowedConnections;
  const authContextParams: {
    ccgid?: string;
    ccgid_hash?: string;
    signup_via_url?: string;
    ggc_continuation_url?: string;
  } = {
    signup_via_url: signup_via_url,
    ggc_continuation_url: ggc_continuation_url
  };
  const prefill: { email?: string; username?: string } = {};
  let allowLogin = true;
  let allowSignUp = true;

  // If a pre-flight id is given adjust the settings to narrow down the logic
  // flow.
  if (ccgid) {
    let preflightCheck;
    try {
      preflightCheck = await userPreflightCheck(language, ccgid);
    } catch (e) {}
    if (preflightCheck && preflightCheck.data && preflightCheck.data.email && preflightCheck.data.ccgid_hash) {
      // Pass on the ccgid to ensure this is available for SIP logins.
      authContextParams.ccgid = ccgid;
      authContextParams.ccgid_hash = preflightCheck.data.ccgid_hash;
      additionalSignUpFields.push({
        type: 'hidden',
        name: 'ccgid',
        value: ccgid
      });
      additionalSignUpFields.push({
        type: 'hidden',
        name: 'ccgid_hash',
        value: preflightCheck.data.ccgid_hash
      });
      prefill.email = preflightCheck.data.email;
      if (preflightCheck.data.hasAuth0) {
        if (preflightCheck.data.isSocial) {
          allowedConnections = ['linkedin'];
        } else {
          allowedConnections = ['Username-Password-Authentication'];
        }
      }
      allowSignUp = !preflightCheck.data.hasAuth0;
      allowLogin = !!preflightCheck.data.hasAuth0;
    }
  }
  // Ensure we don't overlap multiple locks by getting rid of existing ones
  // before creating a new one.
  if (locks.length) {
    locks = locks.filter(deprecatedLock => {
      deprecatedLock.hide();
      return false;
    });
  }
  if (lock) {
    lock.hide();
    lock = undefined;
  }
  // Remove all lock containers.
  const lockContainers = document.querySelectorAll('.auth0-lock-container');
  lockContainers.forEach(e => {
    e.remove();
  });

  lockConfig = {
    language,
    configurationBaseUrl: publicRuntimeConfig.AUTH0_CONFIG_BASE_URL,
    closable: false,
    autoclose: false,
    rememberLastLogin: false,
    container: 'inline-login-container',
    allowedConnections: allowedConnections,
    prefill: prefill,
    allowLogin: allowLogin,
    allowSignUp: allowSignUp,
    auth: {
      audience: publicRuntimeConfig.AUTH0_AUDIENCE,
      redirectUrl: `${publicRuntimeConfig.AUTH0_CALLBACK_URL}`,
      responseType: 'token id_token',
      redirect: false,
      params: {
        sge_metadata: authContextParams
      }
    },
    // Monkey patch additional pending properties: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/52323
    additionalSignUpFields: additionalSignUpFields as (Auth0LockAdditionalSignUpField & {
      storage?: string;
      value?: string;
    })[]
  };
  lock = new Auth0Lock(publicRuntimeConfig.AUTH0_CLIENT_ID, publicRuntimeConfig.AUTH0_DOMAIN, lockConfig);
  locks.push(lock);

  return lock;
};

function translationKey2Text(translationKey: string) {
  const translations = document.querySelector('.translations');
  const translation = translations?.querySelector(`.translation[data-translation-key="${translationKey}"]`);
  return translation?.textContent;
}

function htmlToElement(html: string) {
  const template = document.createElement('template');
  const htmlTrimmed = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = htmlTrimmed;
  return template.content.firstChild;
}

const insertAtTheBeginingInto = (parentElement: Element | HTMLElement | undefined | null, html: string): void => {
  const el = htmlToElement(html);
  if (el) parentElement?.prepend(el);
};

const insertAtTheEndInto = (parentElement: Element | HTMLElement | undefined | null, html: string): void => {
  const el = htmlToElement(html);
  if (el) parentElement?.append(el);
  //el.outerHTML = html;
};

const onBackButtonClick = () => {
  lock?.hide();
  lock?.show({ initialScreen: 'login' });
};

const showLoadAnimation = () => {
  if (onLoadingStateChange_) {
    onLoadingStateChange_('loading-content');
    setTimeout(() => {
      if (onLoadingStateChange_) onLoadingStateChange_('initial');
    }, 1100);
  }
};

const showOpenLinkFormAnimation = () => {
  showLoadAnimation();
};

const sentLinkAnimation = () => {
  const cont = document.querySelector('.auth0-lock-widget-container');
  if (cont?.getAttribute('current-state') == 'resetpassword') showLoadAnimation();
};

const applyNewLoginChanges = (currentState: viewType): void => {
  //return;
  const cont = document.querySelector('.auth0-lock-widget-container');
  if (cont) {
    if (cont?.getAttribute('current-state') != currentState.toString()) {
      cont?.setAttribute('current-state', currentState);
    }
    const givenNameContainer = cont.querySelector('.auth0-lock-input-given_name');
    const familyNameContainer = cont.querySelector('.auth0-lock-input-family_name');
    const emailContainer = cont.querySelector('.auth0-lock-input-email');
    const passwordContainer = cont.querySelector('.auth0-lock-input-show-password');

    const givenNameContainerLabel = cont.querySelector('.auth0-lock-input-given_name-label');
    const familyNameContainerLabel = cont.querySelector('.auth0-lock-input-family_name-label');
    const emailContainerLabel = cont.querySelector('.auth0-lock-input-email-label');
    const passwordContainerLabel = cont.querySelector('.auth0-lock-input-show-password-label');
    const auth0LockAlternativeLink = document.querySelector('.auth0-lock-alternative-link');

    const allInputs = [givenNameContainer, familyNameContainer, emailContainer, passwordContainer];
    const allInputsExceptPassword = [givenNameContainer, familyNameContainer, emailContainer];

    //Case for ccgid - only linked in button case
    cont?.setAttribute('only-linked-in-button', emailContainer ? 'no' : 'yes');

    if (!givenNameContainerLabel)
      insertAtTheBeginingInto(
        givenNameContainer,
        `<div class="auth0-lock-input-given_name-label input-label">${translationKey2Text(
          'signup.input.firstName.label'
        )}</div>`
      );

    if (!familyNameContainerLabel)
      insertAtTheBeginingInto(
        familyNameContainer,
        `<div class="auth0-lock-input-family_name-label input-label">${translationKey2Text(
          'signup.input.lastName.label'
        )}</div>`
      );

    if (!emailContainerLabel)
      insertAtTheBeginingInto(
        emailContainer,
        `<div class="auth0-lock-input-email-label input-label">${translationKey2Text(
          'signupLogin.input.email.label'
        )}</div>`
      );

    let passwordInputLabel = '';

    if (currentState == 'login') {
      passwordInputLabel = translationKey2Text('login.input.password.label') || '';
      window.document.title = translationKey2Text('login.pageTabTitle') + ' | ' + translationKey2Text('sge');
    }

    if (currentState == 'join') {
      passwordInputLabel = translationKey2Text('signup.input.choosePassword.label') || '';
      window.document.title = translationKey2Text('signup.pageTabTitle') + ' | ' + translationKey2Text('sge');
    }

    if (currentState == 'resetpassword') {
      window.document.title = translationKey2Text('forgotPassword.pageTabTitle') + ' | ' + translationKey2Text('sge');
    }

    const redirectUrl = window.localStorage.getItem(LOGIN_REDIRECT_URL_KEY);
    //Bug 26118: Revamp GGC Sign-up/Log-in - Use generic pageTabTitle-labels also for OpenGraph Titles
    //In case of deep link we don't want to use login/join/resetpassword specific titles but rather deep link
    //specifict titles to show the right teasers on the page preview.
    if (!redirectUrl || redirectUrl.includes('/cockpit')) {
      window.document?.querySelector('[property="og:title"]')?.setAttribute('content', window.document.title);
      window.document?.querySelector('[name="twitter:title"]')?.setAttribute('content', window.document.title);
    }

    if (!passwordContainerLabel) {
      insertAtTheBeginingInto(
        passwordContainer,
        `<div class="auth0-lock-input-show-password-label input-label">${passwordInputLabel}</div>`
      );
    } else {
      passwordContainerLabel.textContent = passwordInputLabel;
    }

    allInputs.forEach((element, key) => {
      element?.setAttribute('class', element?.getAttribute('class') + ' input-box');
      element?.querySelector('input')?.setAttribute('placeholder', '');
      element?.querySelector('input')?.setAttribute('tabindex', '' + (key + 1));
    });

    allInputsExceptPassword.forEach(element => {
      element?.querySelector('input')?.setAttribute('data-hj-allow', 'true');
    });

    //Moving linkedin button

    //After returning from password change link it adds second auth-lock-social-buttons-pane
    const socialButtonsWrappers = cont.querySelectorAll('.auth-lock-social-buttons-pane');
    if (socialButtonsWrappers.length > 1) {
      socialButtonsWrappers[socialButtonsWrappers.length - 1].remove();
    }
    const socialButtonsWrapper = cont.querySelector('.auth-lock-social-buttons-pane');
    const submit = cont.querySelector('.auth0-lock-submit');
    const parentElement = submit?.parentElement;

    if (!cont.querySelector('.action-buttons-box'))
      insertAtTheEndInto(parentElement, '<div class="action-buttons-box"></div>');

    const actionButtonBox = cont.querySelector('.action-buttons-box');

    if (socialButtonsWrapper) {
      actionButtonBox?.appendChild(socialButtonsWrapper);
    }
    if (submit) {
      actionButtonBox?.appendChild(submit);

      //On click hook for send reset password animation
      submit.removeEventListener('click', sentLinkAnimation);
      submit.addEventListener('click', sentLinkAnimation);
    }

    if (!cont.querySelector('.auth0-lock-back-button-sge'))
      insertAtTheBeginingInto(
        actionButtonBox,
        `<div class="auth0-lock-back-button-sge" visible="no"><span class="icon"></span><span class="text">${translationKey2Text(
          'forgotPassword.back'
        )}</span></div>`
      );

    const backButton = cont.querySelector('.auth0-lock-back-button-sge');

    if (backButton) {
      backButton.removeEventListener('click', onBackButtonClick);
      backButton.addEventListener('click', onBackButtonClick);
    }

    if (auth0LockAlternativeLink && auth0LockAlternativeLink.getAttribute('hooked') != 'true') {
      auth0LockAlternativeLink.setAttribute('hooked', 'true');
      auth0LockAlternativeLink.setAttribute('href', 'resetpassword');
      auth0LockAlternativeLink.addEventListener('click', e => {
        showOpenLinkFormAnimation();

        e.preventDefault();
        return false;
      });
    }

    const submitButton = cont.querySelector('button.auth0-lock-submit');
    const submitButtonLabel = cont.querySelector('.auth0-customlabel-submit');

    let submitButtonLabelText = '';

    if (currentState == 'resetpassword') {
      submitButtonLabelText = translationKey2Text('forgotPassword.sendEmailButton') || '';
    }

    if (currentState == 'join') {
      submitButtonLabelText = translationKey2Text('signup.button.createYourCockpit') || '';
    }

    if (currentState == 'login') {
      submitButtonLabelText = translationKey2Text('login.button.goToYourCockpit') || '';
    }

    if (!submitButtonLabel) {
      insertAtTheEndInto(submitButton, '<div class="auth0-customlabel-submit">' + submitButtonLabelText + '</div>');
    } else {
      submitButtonLabel.textContent = submitButtonLabelText;
    }

    if (currentState == 'resetpassword') {
      cont.querySelector('.auth0-lock-back-button-sge')?.setAttribute('visible', 'yes');
      socialButtonsWrapper?.setAttribute('visible', 'no');
    } else {
      cont.querySelector('.auth0-lock-back-button-sge')?.setAttribute('visible', 'no');
      socialButtonsWrapper?.setAttribute('visible', 'yes');
    }
  }
};

function isHidden(el: Element) {
  const style = window.getComputedStyle(el);
  return style.display === 'none';
}

const getCurrentState = (): viewType => {
  const cont = document.querySelector('.auth0-lock-widget-container');

  if (cont) {
    const givenNameContainer = cont.querySelector('.auth0-lock-input-given_name');
    //const familyNameContainer = cont.querySelector('.auth0-lock-input-family_name');
    const emailContainer = cont.querySelector('.auth0-lock-input-email');
    const passwordContainer = cont.querySelector('.auth0-lock-input-show-password');

    if (givenNameContainer && !isHidden(givenNameContainer)) {
      return 'join';
    }

    if (passwordContainer && !isHidden(passwordContainer) && (!givenNameContainer || isHidden(givenNameContainer))) {
      return 'login';
    }

    if (
      (!passwordContainer || isHidden(passwordContainer)) &&
      (!givenNameContainer || isHidden(givenNameContainer)) &&
      emailContainer &&
      !isHidden(emailContainer)
    ) {
      return 'resetpassword';
    }
  }

  return 'unknown';
};

let lastState: viewType = 'unknown';

const hookFurtherChanges = () => {
  if (hooked) return;

  hooked = true;

  const observer = new MutationObserver(() => {
    const currentState = getCurrentState();

    if (lastState != currentState) {
      if (onViewChange_) onViewChange_(currentState);
      applyNewLoginChanges(currentState);
    }

    lastState = currentState;
  });

  // Start observing the target node for configured
  const targetNode = document.querySelector('#inline-login-container');
  if (targetNode) {
    observer.observe(targetNode, { attributes: true, childList: true, subtree: true });
  }
};

let adjustLoginDialogueInProgress = false;
const adjustLoginDialogue = async (lock: any, lockConfig: Auth0LockConstructorOptions, language: Locale) => {
  if (adjustLoginDialogueInProgress) return;

  adjustLoginDialogueInProgress = true;
  const lockContainer = document.querySelector('.auth0-lock-widget-container');

  //In the old implementation it was not possible to login in case there was prefilled email so
  //below is the same
  const swithToSignupBox = document.querySelector('.swith-to-signup-box');
  const swithToLoginBox = document.querySelector('.swith-to-login-box');

  swithToSignupBox?.setAttribute('hidden', 'false');
  swithToLoginBox?.setAttribute('hidden', 'false');

  if (!lockConfig.allowLogin) {
    swithToLoginBox?.setAttribute('hidden', 'true');
  }

  if (!lockConfig.allowSignUp) {
    swithToSignupBox?.setAttribute('hidden', 'true');
  }

  hookFurtherChanges();
  applyNewLoginChanges(getCurrentState());

  // Without time timeout the signup.changeUserLink message sometines doesn't show up.
  setTimeout(() => {
    if (lockConfig.auth?.params?.sge_metadata?.ccgid && lockContainer) {
      if (lockConfig.prefill?.email) {
        const emailInput = lockContainer.querySelector('input[name="email"]');
        if (emailInput) {
          emailInput.setAttribute('readonly', 'true');
          //swithToAnotherScreenBox?.
        }

        const lockContentContainer = lockContainer.querySelector('.auth0-lock-form');
        const alternativeContainer = lockContainer.querySelector('.auth0-lock-change-user');

        if (lockContentContainer && !alternativeContainer) {
          // Add bail link.
          const bailLinkContainer = document.createElement('p');
          bailLinkContainer.setAttribute('class', 'auth0-lock-alternative auth0-lock-change-user');
          const bailLink = document.createElement('a');

          const changeUserLink = translationKey2Text('signup.changeUserLink');

          bailLink.innerHTML = (
            '<i>' +
            new IntlMessageFormat(changeUserLink || '', language).format({
              email: '"' + lockConfig.prefill?.email + '"'
            }) +
            '</i>'
          ).replace(/\? */, '?<br>');
          // Clean ccgid from refresh link.
          bailLink.href = window.location.href.replace(/(\?|&)ccgid=[^&]*(&|)/g, '$1').replace(/\?$/, '');
          bailLink.setAttribute('class', 'auth0-lock-alternative-link-ccgid auth0-lock-change-user-link');

          bailLinkContainer.appendChild(bailLink);
          lockContentContainer.insertBefore(bailLinkContainer, lockContentContainer.firstChild);
        }
      }
    }
    adjustLoginDialogueInProgress = false;
  }, 0);
};

export const login = async (language: Locale, ccgid?: string) => {
  // Lock will automatically parse the hashed values on the url when creating a new instance
  if (!lock) {
    lock = await getLock(language, ccgid);
  }
  return new Promise<AuthenticatedProfile>((resolve, reject) => {
    const authenticationCheckProcess = (authResult: AuthResult) => {
      if (onViewChange_) onViewChange_('authentication-in-progress');

      lock?.getUserInfo(authResult.accessToken, (error, profile) => {
        lock?.hide();
        if (error) {
          if (onViewChange_) onViewChange_(getCurrentState());
          reject(error);
        } else {
          resolve(parseProfile(profile, authResult));
        }
      });
    };

    lock?.on('signup ready', () => {
      adjustLoginDialogue(lock, lockConfig || {}, language);
    });
    lock?.on('signin ready', () => {
      adjustLoginDialogue(lock, lockConfig || {}, language);
    });

    lock?.on('show', () => {
      adjustLoginDialogue(lock, lockConfig || {}, language);
    });

    // This is called when lock parses the url
    lock?.on('authenticated', function (authResult) {
      authenticationCheckProcess(authResult);
    });

    lock?.on('authorization_error', function (error) {
      if (!error.code || error.code !== 'invalid_user_password') {
        // DON'T reject on wrong email/password so the user will be able to try again
        reject(error);
      }
    });

    lock?.on('forgot_password submit', function () {
      // Set resume position.
      if (lockConfig?.auth?.params?.sge_metadata?.ggc_continuation_url) {
        // Fetch e-mail used.
        const emailInputs = document.querySelector(
          '#auth0-lock-container-' + lock?.id + ' .auth0-lock-form .auth0-lock-input-email input'
        ) as HTMLInputElement;

        if (emailInputs) {
          if (emailInputs.value) {
            const { publicRuntimeConfig } = getConfig();
            const config = {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ resumeUrl: lockConfig?.auth?.params?.sge_metadata?.ggc_continuation_url })
            };
            // Fire and forget.
            fetch(`${publicRuntimeConfig.API_URI}/v1/en/users/set-resume-url/${emailInputs.value}`, config);
          }
        }
      }
    });

    // Check if we got a login ready
    lock?.checkSession({}, function (err, authResult) {
      if (!err && authResult && authResult.accessToken) {
        authenticationCheckProcess(authResult);
      } else {
        const loc = window.location.pathname;

        if (loc.indexOf('login') !== -1) {
          lock?.show({ initialScreen: 'login' });
        } else if (loc.indexOf('resetpassword') !== -1) {
          lock?.show({ initialScreen: 'forgotPassword' });
        } else {
          lock?.show({ initialScreen: 'signUp' });
        }
      }
    });
  });
};

export const registeronViewChangeFunction = (onViewChange: (newView: viewType) => void) => {
  onViewChange_ = onViewChange;
};

export const registerOnLoadingStateChangeFunction = (
  onLoadingStateChange: (loadingState: loadingStateType) => void
) => {
  onLoadingStateChange_ = onLoadingStateChange;
};

export const showLock = (screen: 'login' | 'signUp' | 'forgotPassword', onShow: () => void) => {
  const lockPromise = lock ? new Promise<Auth0LockStaticInstance | undefined>(resolve => resolve(lock)) : getLock();
  lockPromise?.then(locx => {
    locx?.hide();
    locx?.show({ initialScreen: screen });
    if (screen == 'login') applyNewLoginChanges('login');
    if (screen == 'signUp') applyNewLoginChanges('join');
    if (screen == 'forgotPassword') applyNewLoginChanges('resetpassword');
    onShow();
  });
};

export const refreshToken = async (language?: Locale) => {
  // Lock will automatically parse the hashed values on the url when creating a new instance
  const lock = await getLock(language);

  return new Promise<AuthenticatedProfile>((resolve, reject) => {
    // This is called when lock parses the url
    lock.checkSession({}, function (error, authResult) {
      if (error) {
        return reject(error);
      }

      if (!authResult) {
        return reject();
      }

      lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          return reject(error);
        }

        return resolve(parseProfile(profile, authResult));
      });
    });
  });
};

export const logout = async (returnTo?: string, language?: Locale) => {
  const lock = await getLock(language);

  //
  lock.logout({
    returnTo
  });
};

export const userPreflightCheck = async (locale: Locale, ccgid: string) => {
  return axios.get<AuthPreflightCheck>(
    `${getConfig().publicRuntimeConfig.API_URI}/v1/${locale}/users/preflight-check/${ccgid}`
  );
};
