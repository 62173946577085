import {
  RETRY_SERVER_ACTIONS,
  RetryServerActionsAction,
  ClearServerActionAction,
  CLEAR_SERVER_ACTION,
  ADD_FAILED_SERVER_ACTION,
  AddFailedServerActionsAction
} from './types';

export const doRetryServerActions = (): RetryServerActionsAction => ({
  type: RETRY_SERVER_ACTIONS
});

export const doClearServerActions = (action: ClearServerActionAction['action']): ClearServerActionAction => ({
  type: CLEAR_SERVER_ACTION,
  action
});

export const doAddFailedServerAction = (
  action: AddFailedServerActionsAction['action']
): AddFailedServerActionsAction => ({
  type: ADD_FAILED_SERVER_ACTION,
  action
});
