import { AuthenticatedProfile } from './interfaces';

export const AUTH_USER = 'auth/AUTH_USER';
export const STORE_USER_AUTH = 'auth/STORE_USER_AUTH';
export const LOGOUT_USER = 'auth/LOGOUT_USER';
export const REFRESH_TOKEN_AND_RETRY = 'auth/REFRESH_TOKEN_AND_RETRY';

export interface AuthUserAction {
  type: typeof AUTH_USER;
  redirectUrl?: string;
  ccgid?: string;
  resume?: boolean;
}

export interface StoreUserAuthAction {
  type: typeof STORE_USER_AUTH;
  profile?: AuthenticatedProfile;
}

export interface LogoutUserAction {
  type: typeof LOGOUT_USER;
  redirectUrl?: string;
  skipAuth0?: boolean;
  destinationUrl?: string;
}

export interface RefreshTokenAndRetryAction<T> {
  type: typeof REFRESH_TOKEN_AND_RETRY;
  retryAction: T;
}
