import { AuthenticatedProfile } from '../auth/interfaces';
import { User } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const SIGNUP_OR_LOGIN = 'user/SIGNUP_OR_LOGIN';
export const SIGNUP_OR_LOGIN_SUCCESS = 'user/SIGNUP_OR_LOGIN_SUCCESS';
export const SIGNUP_OR_LOGIN_FAILURE = 'user/SIGNUP_OR_LOGIN_FAILURE';
export const RETRIEVE_USER = 'user/RETRIEVE_USER';
export const RETRIEVE_USER_SUCCESS = 'user/RETRIEVE_USER_SUCCESS';
export const RETRIEVE_USER_FAILURE = 'user/RETRIEVE_USER_FAILURE';
export const UPDATE_USER = 'user/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'user/UPDATE_USER_FAILURE';
export const CLEAR_GENERAL_ERRORS = 'user/CLEAR_GENERAL_ERRORS';
export const SYNC_DB_USER = 'user/SYNC_DB_USER';
export const SYNC_DB_USER_SUCCESS = 'user/SYNC_DB_USER_SUCCESS';
export const SYNC_DB_USER_FAILURE = 'user/SYNC_DB_USER_FAILURE';
export const LOGIN_REDIRECT = 'user/LOGIN_REDIRECT';

export interface SignupOrLoginAction {
  type: typeof SIGNUP_OR_LOGIN;
  auth0Profile: AuthenticatedProfile;
  redirectUrl?: string;
}

export interface SignupOrLoginSuccessAction {
  type: typeof SIGNUP_OR_LOGIN_SUCCESS;
  user?: User;
}

export interface SignupOrLoginFailureAction {
  type: typeof SIGNUP_OR_LOGIN_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveUserAction {
  type: typeof RETRIEVE_USER;
  onSuccess?: (user: User) => void;
  onFailure?: (error: any, parsedError?: any) => void;
}

export interface LoginRedirectAction {
  type: typeof LOGIN_REDIRECT;
}

export interface RetrieveUserSuccessAction {
  type: typeof RETRIEVE_USER_SUCCESS;
  user: User;
}

export interface RetrieveUserFailureAction {
  type: typeof RETRIEVE_USER_FAILURE;
  error?: RequestResult<void>;
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  user: Partial<User>;
  onSuccess?: (user: User) => void;
}

export interface UpdateUserSuccessAction {
  type: typeof UPDATE_USER_SUCCESS;
  user: User;
  successMessageId?: string;
}

export interface UpdateUserFailureAction {
  type: typeof UPDATE_USER_FAILURE;
  errorMessageId?: string;
  error?: RequestResult<void>;
}

export interface ClearGeneralErrorsAction {
  type: typeof CLEAR_GENERAL_ERRORS;
}

export interface SyncDBUserAction {
  type: typeof SYNC_DB_USER;
  redirectUrl?: string;
}

export interface SyncDBUserSuccessAction {
  type: typeof SYNC_DB_USER_SUCCESS;
  user?: User;
}

export interface SyncDBUserFailureAction {
  type: typeof SYNC_DB_USER_FAILURE;
  error?: RequestResult<void>;
}
