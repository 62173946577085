import {
  SignupOrLoginAction,
  SIGNUP_OR_LOGIN,
  SignupOrLoginSuccessAction,
  SIGNUP_OR_LOGIN_SUCCESS,
  SIGNUP_OR_LOGIN_FAILURE,
  SignupOrLoginFailureAction,
  RETRIEVE_USER,
  RetrieveUserAction,
  RETRIEVE_USER_SUCCESS,
  RETRIEVE_USER_FAILURE,
  RetrieveUserSuccessAction,
  RetrieveUserFailureAction,
  UpdateUserAction,
  UPDATE_USER,
  UpdateUserSuccessAction,
  UpdateUserFailureAction,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_GENERAL_ERRORS,
  SyncDBUserAction,
  SyncDBUserSuccessAction,
  SyncDBUserFailureAction,
  SYNC_DB_USER,
  SYNC_DB_USER_SUCCESS,
  SYNC_DB_USER_FAILURE,
  ClearGeneralErrorsAction,
  LoginRedirectAction,
  LOGIN_REDIRECT
} from './types';
import { User } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doSignupOrLogin = (
  auth0Profile: SignupOrLoginAction['auth0Profile'],
  redirectUrl?: SignupOrLoginAction['redirectUrl']
): SignupOrLoginAction => ({
  auth0Profile,
  type: SIGNUP_OR_LOGIN,
  redirectUrl
});

export const doSignupOrLoginSuccess = (user?: SignupOrLoginSuccessAction['user']): SignupOrLoginSuccessAction => ({
  user,
  type: SIGNUP_OR_LOGIN_SUCCESS
});

export const doSignupOrLoginFailure = (error: RequestResult<void>): SignupOrLoginFailureAction => ({
  type: SIGNUP_OR_LOGIN_FAILURE,
  error
});

export const doRetrieveUser = (
  onSuccess?: RetrieveUserAction['onSuccess'],
  onFailure?: RetrieveUserAction['onFailure']
): RetrieveUserAction => ({
  type: RETRIEVE_USER,
  onSuccess,
  onFailure
});

export const doLoginRedirect = (): LoginRedirectAction => ({
  type: LOGIN_REDIRECT
});

export const doRetrieveUserSuccess = (user: RetrieveUserSuccessAction['user']): RetrieveUserSuccessAction => ({
  user,
  type: RETRIEVE_USER_SUCCESS
});

export const doRetrieveUserFailure = (error?: RequestResult<void>): RetrieveUserFailureAction => ({
  type: RETRIEVE_USER_FAILURE,
  error
});

export const doUpdateUser = (user: UpdateUserAction['user'], onSuccess?: (user: User) => void): UpdateUserAction => ({
  user,
  onSuccess,
  type: UPDATE_USER
});

export const doUpdateUserSuccess = (
  user: UpdateUserSuccessAction['user'],
  successMessageId?: string
): UpdateUserSuccessAction => ({
  successMessageId,
  user,
  type: UPDATE_USER_SUCCESS
});

export const doUpdateUserFailure = (error?: RequestResult<void>): UpdateUserFailureAction => ({
  type: UPDATE_USER_FAILURE,
  error
});

export const doSyncDBUser = (redirectUrl?: string): SyncDBUserAction => ({
  type: SYNC_DB_USER,
  redirectUrl
});

export const doSyncDBUserSuccess = (user: SyncDBUserSuccessAction['user']): SyncDBUserSuccessAction => ({
  type: SYNC_DB_USER_SUCCESS,
  user
});

export const doSyncDBUserFailure = (error?: RequestResult<void>): SyncDBUserFailureAction => ({
  type: SYNC_DB_USER_FAILURE,
  error
});

export const doClearGeneralErrors = (): ClearGeneralErrorsAction => ({
  type: CLEAR_GENERAL_ERRORS
});
