import cookie from 'js-cookie';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import logging from '../../../logging';
import { login, logout, refreshToken } from '../../../utils/auth0Utils';
import { AppState } from '../../reducers';
import { doAddFailedServerAction } from '../actions/actions';
import { doSignupOrLogin } from '../user/actions';
import { doLogoutUser, doStoreUserAuth } from './actions';
import { AuthenticatedProfile } from './interfaces';
import {
  AuthUserAction,
  AUTH_USER,
  LogoutUserAction,
  LOGOUT_USER,
  RefreshTokenAndRetryAction,
  REFRESH_TOKEN_AND_RETRY
} from './types';
import getConfig from 'next/config';

function* workerStoreCookies(auth0Profile: AuthenticatedProfile) {
  // We're splitting the auth into multiple cookies to prevent getting to the cookie size limit

  // Store only the auth0 profile separately
  cookie.set(
    'authProfileJSON',
    encodeURIComponent(JSON.stringify({ ...auth0Profile, tokenPayload: undefined, accessToken: undefined })),
    {
      expires: 1
    }
  );

  // Store only the access token
  cookie.set('accessToken', encodeURIComponent(auth0Profile.accessToken), { expires: 1 });

  if (auth0Profile.tokenPayload) {
    // Store only the token payload
    cookie.set('tokenPayloadJSON', encodeURIComponent(JSON.stringify(auth0Profile.tokenPayload)), { expires: 1 });
  }
}

function* workerRemoveCookies() {
  cookie.remove('authProfileJSON');
  cookie.remove('accessToken');
  cookie.remove('tokenPayloadJSON');
}

export const LOGIN_REDIRECT_URL_KEY = 'sge:login_redirect_url';

function* workerLoginUser({ redirectUrl, ccgid, resume }: AuthUserAction) {
  try {
    const locale = yield select((state: AppState) => state.appReducer.language);

    if (redirectUrl) {
      window.localStorage.setItem(LOGIN_REDIRECT_URL_KEY, redirectUrl);
    }

    const profile: AuthenticatedProfile = yield call(login, locale, ccgid);

    // Check if the resume parameter is set. If so see if the auth0 profile has
    // resume information.
    if (resume) {
      // I've no idea why the heck we've got two properties for this while only
      // one is actually created in the auth0 handling.
      if (profile.tokenPayload?.['https://goglobal.s-ge.com/ggc_continuation_url']) {
        redirectUrl = profile.tokenPayload?.['https://goglobal.s-ge.com/ggc_continuation_url'];
      } else if (profile.tokenPayload?.['https://goglobal.s-ge.com/ggcContinuationUrl']) {
        redirectUrl = profile.tokenPayload?.['https://goglobal.s-ge.com/ggcContinuationUrl'];
      }
    }

    yield put(doSignupOrLogin(profile, redirectUrl));
    yield call(workerStoreCookies, profile);
    yield put(doStoreUserAuth(profile));
  } catch (error) {
    logging.error('Saga login error', error);
  }
}

function* workerLogoutUser({ redirectUrl, skipAuth0, destinationUrl }: LogoutUserAction) {
  // This is the logout method, which clears the cookies and invalidates auth0 tokens.
  // While logging out, the local storage data is also deleted but since that is handled
  // by redux persist, we do not directly delete keys calling the localStorage function.
  // But rather, there's a interceptor reducer in src\redux\reducers.ts
  // that listens to the LOGOUT_USER event(the same one that triggers this saga), and clears the state.
  // Redux persist will then persist that empty state on local storage. So that's how
  // local storage is cleaned.
  if (process.browser) {
    try {
      const locale = yield select((state: AppState) => state.appReducer.language);
      // Clean the user from the cookies
      yield call(workerRemoveCookies);

      if (!destinationUrl && window.location.search.search('destination=') > -1) {
        const currentUrl = new URL(window.location.href);
        const destinationUrlParam = currentUrl.searchParams.get('destination');
        if (destinationUrlParam) {
          // Validate url - ensure we only allow s-ge.com destinations.
          // Avoids open redirect security issues.
          const destinationUrlObject = new URL(destinationUrlParam);
          if (destinationUrlObject.hostname.search(/^(.+\.|)s-ge\.com$/) > -1) {
            destinationUrl = destinationUrlParam;
          }
        }
      }
      // If this is a logout round-trip send to next destination.
      if (destinationUrl) {
        window.location.assign(destinationUrl);
        return;
      }

      if (!skipAuth0) {
        // After logging out. Send the user back to the login page.
        let returnTo = `${window.location.origin}/${locale}/login${
          redirectUrl
            ? `?redirectUrl=${encodeURIComponent(
                redirectUrl.replace(/(&|)(ccgid|resume)=.*?(&|$|#)/, '').replace(/\?$/, '')
              )}`
            : ''
        }`;

        // For now only enable on certain systems with the flag set.
        if (getConfig().publicRuntimeConfig.AUTH0_GGC_ENABLE_LOGOUT_ROUNDTRIP) {
          // Send the user on a logout round-trip after logging out from auth0.
          // Let auth0 send the user back to the cockpit - and from there we move
          // to the round-trip.
          const cmsRoundTripUrl = new URL(
            `${getConfig().publicRuntimeConfig.CMS_URL.replace(/\/$/, '')}/${locale}/logout`
          );
          cmsRoundTripUrl.searchParams.set('finalDest', returnTo);
          const roundTripUrl = new URL(`${window.location.origin}/${locale}/logout`);
          roundTripUrl.searchParams.set('destination', cmsRoundTripUrl.toString());
          returnTo = roundTripUrl.toString();
        }
        // Call auth0 logout to invalidate token
        yield call(logout, returnTo);
      }
    } catch (error) {
      logging.error(error);
    }
  } else {
    // Don't try to logout on server side
    yield put(doAddFailedServerAction(doLogoutUser()));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* workerRefreshTokenAndRetry({ retryAction }: RefreshTokenAndRetryAction<any>) {
  if (process.browser) {
    const locale = yield select((state: AppState) => state.appReducer.language);
    try {
      let profile: AuthenticatedProfile;
      try {
        // Tries to refresh the token
        profile = yield call(refreshToken, locale);
      } catch (error) {
        const locale = yield select((state: AppState) => state.appReducer.language);
        // If didn't work, display the login modal
        profile = yield call(login, locale);
      }

      if (profile) {
        // With the token, update the user
        yield put(doStoreUserAuth(profile));

        // Store the new version
        yield call(workerStoreCookies, profile);

        // Dispatch the retry action
        yield put(retryAction);
      }
    } catch (error) {
      // If cannot refresh and couldn't login
      logging.error(error);

      // Logout
      yield put(doLogoutUser());
    }
  } else {
    // Don't try to refresh token on server side
    yield put(doAddFailedServerAction(retryAction));
  }
}

export function* watcherLoginUser() {
  yield takeLatest(AUTH_USER, workerLoginUser);
}

export function* watcherLogout() {
  yield takeLatest(LOGOUT_USER, workerLogoutUser);
}

export function* watcherRefreshTokenAndRetry() {
  yield takeLatest(REFRESH_TOKEN_AND_RETRY, workerRefreshTokenAndRetry);
}

export function* authSaga() {
  yield all([call(watcherLoginUser), call(watcherLogout), call(watcherRefreshTokenAndRetry)]);
}
