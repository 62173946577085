import moment from 'moment';

const logging = {
  log: (message: string | object, ...optional: any[]): void => {
    console.log(message, ...optional);
  },
  error: (message: string | object, ...optional: any[]): void => {
    console.error(message, ...optional);
  },
  info: (message: string | object, ...optional: any[]): void => {
    console.info('[' + moment(new Date()).format('hh:mm:ss.SSS') + ']', message, ...optional);
  },
  debug: (message: string | object, ...optional: any[]): void => {
    if (process.env.DEBUG_LOG_ENABLED === 'true') {
      console.debug(message, ...optional);
    }
  },
  critical: (message: string | object, ...optional: any[]): void => {
    console.error(message, ...optional);
  },
  warn: (message: string | object, ...optional: any[]): void => {
    console.warn(message, ...optional);
  },
  warning: (message: string | object, ...optional: any[]): void => {
    console.warn(message, ...optional);
  }
};
export default logging;
