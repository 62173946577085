import { Action } from 'redux';

export const RETRY_SERVER_ACTIONS = 'actions/RETRY_SERVER_ACTIONS';
export const ADD_FAILED_SERVER_ACTION = 'actions/ADD_FAILED_SERVER_ACTION';
export const CLEAR_SERVER_ACTION = 'actions/CLEAR_SERVER_ACTION';

export interface RetryServerActionsAction {
  type: typeof RETRY_SERVER_ACTIONS;
}

export interface ClearServerActionAction {
  type: typeof CLEAR_SERVER_ACTION;
  action: Action;
}

export interface AddFailedServerActionsAction {
  type: typeof ADD_FAILED_SERVER_ACTION;
  action: Action;
}
